import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@mui/material/styles'
import { isEqual } from 'lodash'
import { useVenti } from 'venti'
import {
	Tabs,
	Tab,
	Card,
	CardContent,
	FormControlLabel,
	Checkbox,
	FormGroup,
	Typography,
} from '@mui/material'

import Page from '../../../components/Page'
import TabPanel from '../../../components/TabPanel'
import { LoadingBtn } from '../../../components/Button'
import { ModalYesNo } from '../../../components/modals/ModalYesNo'
import { Loading } from '../../../components/Loading'
import { Features } from '../../../components/admin/Features'
import { WebsiteSettings } from '../../../components/admin/WebsiteSettings'
import { Integrations } from '../../../components/admin/Integrations'
import { ProfileView } from './Profile'

import { navigationLinking } from '../../../services/navigation'
import { siteConfigurationModel } from '../../../services/utils'
import {
	createCorporation,
	getCorporationSiteConfiguration,
	createCorporationSiteConfiguration,
	updateCorporationSiteConfiguration,
} from '../../../services/client'
import {
	a11yProps,
	getErrorMessage,
	validateSiteConfigurationData,
} from '../../../services/helper'
import { eventTypes } from '../../../services/constants'
import queryKeys from '../../../services/queryKeys'

import { useWindowSize, useAlert } from '../../../hooks'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { getTheme } from '../../../config'

const theme = getTheme()

export default function AdminCorporateEdit({ navigation }) {
	const navigate = useNavigate()
	const { alert } = useAlert()
	const { id } = useParams()
	const queryClient = useQueryClient()
	const ventiState = useVenti()
	const [width] = useWindowSize()
	const themeMUI = useTheme()
	const mixpanel = useMixpanel()
	const isCreateNewCorporation = id === 'new'
	const siteConfigurationId = ventiState.get(
		theme.storageKeys.editingCorporateSiteConfigurationId
	)
	const [tab, setTab] = useState(0)
	const [modalConfirmation, setModalConfirmation] = useState({
		open: false,
		text: '',
		leavePage: false,
		canBeSaved: false,
		isSave: false,
		event: null,
	})
	const [initialState, setInitialState] = useState({
		...siteConfigurationModel,
		entityType: 1,
	})
	const [formData, setFormData] = useState({
		...siteConfigurationModel,
		entityType: 1,
	})
	const [inheritedSiteConfig, setInheritedSiteConfig] = useState({})
	const [canBeSubmitted, setCanBeSubmitted] = useState(false)
	const initialRender = useRef(true)
	const {
		error,
		data: corporationSiteConfigurationResult,
		refetch,
	} = useQuery({
		queryKey: [
			queryKeys.corporationSiteConfiguration,
			siteConfigurationId,
		],
		queryFn: () =>
			getCorporationSiteConfiguration(id, siteConfigurationId),
		enabled: !!id && !isCreateNewCorporation && !!siteConfigurationId,
		retry: 2,
	})

	useEffect(() => {
		if (!id & !siteConfigurationId)
			navigate(`/${navigationLinking.AdminCorporate}`)
	}, [id])

	useEffect(() => {
		if (error) {
			alert(
				'There was a problem loading the corporation site configuration',
				{
					severity: 'error',
				}
			)
		}
	}, [error])

	useEffect(() => {
		if (
			!isCreateNewCorporation &&
			siteConfigurationId &&
			corporationSiteConfigurationResult
		) {
			// with a V2 api we have one to many siteConfigurations
			// so we should think later how to display that on UI
			setFormData(
				corporationSiteConfigurationResult.siteConfiguration
			)
			setInheritedSiteConfig(
				corporationSiteConfigurationResult.inheritedSiteConfiguration
			)
			setInitialState(
				corporationSiteConfigurationResult.siteConfiguration
			)
		}
	}, [id, siteConfigurationId, corporationSiteConfigurationResult])

	useEffect(() => {
		const canBeSubmitted = !!(
			formData.url.trim() !== '' &&
			formData.licenses.length > 0 &&
			formData.name.length &&
			!isEqual(initialState, formData)
		)

		setCanBeSubmitted(canBeSubmitted)

		if (initialRender.current) {
			initialRender.current = false
		}
	}, [formData, initialState])

	const invalidateSiteConfigurationQueries = async () => {
		await queryClient.invalidateQueries({
			queryKey: [
				queryKeys.corporationSiteConfiguration,
				siteConfigurationId,
			],
		})
		await refetch()
	}

	const createCorporationMutation = useMutation({
		mutationFn: (data) => createCorporation(data),
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: [queryKeys.corporations],
			})
		},
	})

	const createCorporationSiteConfigurationMutation = useMutation({
		mutationFn: ({ formData, corporationId }) =>
			createCorporationSiteConfiguration(formData, corporationId),
	})

	const updateCorporationSiteConfigurationMutation = useMutation({
		mutationFn: ({ formData, corporateId, siteConfigurationId }) =>
			updateCorporationSiteConfiguration(
				formData,
				corporateId,
				siteConfigurationId
			),
		onSuccess: invalidateSiteConfigurationQueries,
	})

	const saveForm = async () => {
		try {
			const { id: formId, name } = formData
			if (formId) {
				await updateCorporationSiteConfigurationMutation.mutateAsync({
					formData,
					corporateId: id,
					siteConfigurationId: formId,
				})
				mixpanel.trackEvent(eventTypes.BRAND_UPDATED, {
					name,
					id,
				})
				alert(`Brand "${name}" successfully updated`)
				navigate(`/${navigationLinking.AdminCorporate}`)
			} else {
				const corporation =
					await createCorporationMutation.mutateAsync({
						name,
					})
				validateSiteConfigurationData(formData)

				await createCorporationSiteConfigurationMutation.mutateAsync({
					formData,
					corporationId: corporation.id,
				})

				mixpanel.trackEvent(eventTypes.BRAND_CREATED, {
					name,
					id: corporation.id,
				})
				alert(`Brand "${name}" successfully created`)
				navigate(`/${navigationLinking.AdminCorporate}`)
			}
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleCheckFormLicenses = () => {
		formData.licenses.length === 0
			? setModalConfirmation({
					open: true,
					text: theme.modal.licensesText,
					leavePage: false,
					canBeSaved: false,
					isSave: true,
					event: null,
				})
			: saveForm()
	}

	const modalConfirm = async (modalConfirmation) => {
		modalConfirmation.leavePage
			? navigation.dispatch(modalConfirmation.event.data.action)
			: setModalConfirmation({
					open: false,
					text: '',
					leavePage: false,
				})
	}

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	return (
		<Page
			isFullWidth={true}
			title={isCreateNewCorporation ? 'New Brand' : 'Edit Brand'}
		>
			<ModalYesNo
				modalConfirmation={modalConfirmation}
				modalConfirm={modalConfirm}
				setModalConfirmation={setModalConfirmation}
			/>
			<div className="pl-5 pr-5 pb-20 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">
					{isCreateNewCorporation ? 'New Brand' : 'Edit Brand'}
				</p>
				{!isCreateNewCorporation && !initialState.id ? (
					<div
						className="flex justify-center dark:bg-[#121212]"
						style={{ height: `calc(100vh - 216px)` }}
					>
						<Loading size="small" />
					</div>
				) : (
					<>
						<div className="flex flex-wrap justify-end my-5">
							{/*{formData?.id && (*/}
							{/*	<FormGroup>*/}
							{/*		<FormControlLabel*/}
							{/*			label={*/}
							{/*				<Typography className="dark:text-white">*/}
							{/*					Apply to All Child Sites*/}
							{/*				</Typography>*/}
							{/*			}*/}
							{/*			key="AdminCorporateEditApplyToChildrenButton"*/}
							{/*			control={*/}
							{/*				<Checkbox*/}
							{/*					id="AdminCorporateEditApplyToChildrenButton"*/}
							{/*					checked={formData?.updateDownstream || false}*/}
							{/*					onChange={() =>*/}
							{/*						setFormData((current) => ({*/}
							{/*							...current,*/}
							{/*							updateDownstream:*/}
							{/*								!current.updateDownstream,*/}
							{/*						}))*/}
							{/*					}*/}
							{/*					inputProps={{ 'aria-label': 'controlled' }}*/}
							{/*				/>*/}
							{/*			}*/}
							{/*		/>*/}
							{/*	</FormGroup>*/}
							{/*)}*/}
							<LoadingBtn
								id={`AdminCorporateEditSaveButton`}
								disabled={!canBeSubmitted}
								loading={
									updateCorporationSiteConfigurationMutation.isPending ||
									createCorporationSiteConfigurationMutation.isPending
								}
								text={`${isCreateNewCorporation ? 'Create Brand' : 'Update Brand'}`}
								onClick={handleCheckFormLicenses}
								fullWidth={false}
							/>
						</div>
						<Tabs
							variant="scrollable"
							scrollButtons="auto"
							value={tab}
							onChange={handleTabChange}
							aria-label="Tabs"
						>
							<Tab label="Corporate Profile" {...a11yProps(0)} />
							<Tab label="Branding & Images" {...a11yProps(1)} />
							<Tab label="Features" {...a11yProps(2)} />
							<Tab label="Integrations" {...a11yProps(3)} />
						</Tabs>
						<SwipeableViews
							axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
							index={tab}
							onChangeIndex={handleChangeIndex}
						>
							<TabPanel
								value={tab}
								index={0}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<ProfileView
											formData={formData}
											setFormData={setFormData}
											inheritedSiteConfiguration={inheritedSiteConfig}
											width={width}
											isTopLevelSite={true}
										/>
									</CardContent>
								</Card>
							</TabPanel>
							<TabPanel
								value={tab}
								index={1}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<WebsiteSettings
											formData={formData}
											setFormData={setFormData}
											inheritedSiteConfiguration={inheritedSiteConfig}
											entityType={1}
											width={width}
											isTopLevelSite={true}
										/>
									</CardContent>
								</Card>
							</TabPanel>
							<TabPanel
								value={tab}
								index={2}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<Features
											formData={formData}
											setFormData={setFormData}
											inheritedSiteConfiguration={inheritedSiteConfig}
											width={width}
											isTopLevelSite={true}
										/>
									</CardContent>
								</Card>
							</TabPanel>
							<TabPanel
								value={tab}
								index={3}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<Integrations
											formData={formData}
											setFormData={setFormData}
											inheritedSiteConfiguration={inheritedSiteConfig}
											entityType={1}
											width={width}
											isTopLevelSite={true}
										/>
									</CardContent>
								</Card>
							</TabPanel>
						</SwipeableViews>
					</>
				)}
			</div>
		</Page>
	)
}
