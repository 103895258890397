// NPM packages
import React, { useEffect, useRef, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import SwipeableViews from 'react-swipeable-views'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { useTheme } from '@mui/material/styles'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useMutation } from '@tanstack/react-query'
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material'
import { isEqual } from 'lodash'
// Components
import { useAppContext } from '../../../components/AppContext'
import Page from '../../../components/Page'
import ChangePassword from '../../../components/ChangePassword'
import { Button, LoadingBtn } from '../../../components/Button'
import { ModalUploadSizeLimit } from '../../../components/modals/ModalUploadSizeLimit'
import { ImagePicker } from '../../../components/admin/WebsiteSettings'
import { TextEditor } from '../../../components/formControls/TextEditor'
import { ModalYesNo } from '../../../components/modals/ModalYesNo'
import { ModalDeleteMe } from '../../../components/modals/ModalDeleteMe'
import ModalEditMe from '../../../components/modals/Dialog'
import UserProfileSettings from './UserProfileSettings'
// Services
import {
	a11yProps,
	getErrorMessage,
	isEmailValid,
	states,
} from '../../../services/helper'
import {
	changePassword,
	Roles,
	updatePartnerSiteConfiguration,
	updateLoanOfficerSiteConfiguration,
	updateMe,
	deleteMe,
} from '../../../services/client'
import { applyMask, fileMBSizes } from '../../../services/utils'
import { useAlert, useWindowSize } from '../../../hooks'
import { getTheme } from '../../../config'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { eventTypes } from '../../../services/constants'
import StateLicensesList from '../../../components/StateLicensesList'
import UserProfileForm from '../../../forms/UserProfileForm'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <div className="mt-5">{children}</div>}
		</div>
	)
}

const theme = getTheme()

export default function UserProfile({ navigation }) {
	const muiTheme = useTheme()
	const [width] = useWindowSize()
	const { alert } = useAlert()
	const { state } = useAppContext()
	const initialRender = useRef(true)
	const mixpanel = useMixpanel()
	const { user, siteConfig } = state

	const [tab, setTab] = useState(0)
	const [userPassword, setUserPassword] = useState('')
	const [oldPassword, setOldPassword] = useState('')
	const [passwordConfirmed, setPasswordConfirmed] = useState('')
	const [enabledButton, setEnabledButton] = useState(true)
	const [showOldPassword, setShowOldPassword] = useState(false)
	const [initialState, setInitialState] = useState({
		...user,
		...siteConfig,
	})
	const [formData, setFormData] = useState({ ...user, ...siteConfig })
	const [modalSizeLimitVisible, setModalSizeLimitVisible] =
		useState(false)
	const [inValidFields, setInValidFields] = useState([])
	const [canBeSubmitted, setCanBeSubmitted] = useState(true)
	const [modalConfirmation, setModalConfirmation] = useState({
		open: false,
		text: '',
		leavePage: false,
		canBeSaved: false,
		isSave: false,
		event: null,
	})
	const [modalDeleteMe, setModalDeleteMe] = useState({
		visible: false,
		title: 'Submit a request to delete my user account',
		description:
			'The process of fulfilling your deletion request may take several days to process. You will receive an email notification from our support team once your request has been completed.',
	})
	const [isModalEditMeOpen, setIsModalEditMeOpen] = useState(false)

	useEffect(() => {
		const { domain, ...rest } = formData
		const canBeSubmitted =
			formData?.url?.length > 0 &&
			isEmailValid(formData?.email?.trim()) &&
			// formData?.phone?.replace(/\D/g, "")?.length === 10 &&
			formData?.firstName?.trim()?.length > 0 &&
			formData?.lastName?.trim()?.length > 0 &&
			formData?.title?.trim()?.length > 0 &&
			formData?.licenses?.length > 0 &&
			// formData?.branchID?.length > 0 &&
			!isEqual(initialState, rest)

		setCanBeSubmitted(canBeSubmitted)

		if (initialRender.current) {
			initialRender.current = false
		}
	}, [formData, initialState])

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	const updatePOSSiteConfiguration = (field, value) => {
		setFormData((formData) => ({ ...formData, [field]: value }))
	}

	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader()
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				resolve(fileReader.result)
			}
			fileReader.onerror = (error) => {
				reject(error)
			}
		})
	}

	const handleIntroductionChange = (newIntroduction) => {
		if (newIntroduction !== formData.introduction) {
			setFormData({
				...formData,
				introduction: newIntroduction,
			})
		}
	}

	const handleImagePicker = async (e, field) => {
		const file = e.target.files[0]

		if (file?.size > fileMBSizes['20']) {
			setModalSizeLimitVisible(true)
		} else {
			const base64 = await convertBase64(file)
			setFormData({ ...formData, [field]: base64 })
		}
	}

	const handleOnChangeText = (text, nativeID, field, action) => {
		if (field === 'phone' || field === 'tollFree') {
			text = applyMask(text, 'phone')
		}

		if (action) action(text)
		if (field)
			updatePOSSiteConfiguration(field, text.trim().toLowerCase())

		if (text) {
			setInValidFields(
				inValidFields.filter((item) => item !== nativeID)
			)
		} else {
			setInValidFields([nativeID, ...inValidFields])
			handleInputValid(text)
		}
	}

	const handleStateLicensesSelect = (selectedState) => {
		let { licenses } = formData
		if (selectedState === 'all')
			licenses = states.map((state) => state.value)
		else if (selectedState === 'none') licenses = []
		else {
			const isChecked = licenses.includes(selectedState)
			if (isChecked)
				licenses = formData.licenses.filter(
					(l) => l !== selectedState
				)
			else licenses.push(selectedState)
		}

		setFormData((formData) => ({ ...formData, licenses }))
	}

	const handleInputValid = (id) => {
		return inValidFields.includes(id)
	}

	const updateFirstName = (text) =>
		setFormData((formData) => ({
			...formData,
			firstName: text,
			name: `${text} ${formData.lastName}`,
		}))
	const updateLastName = (text) =>
		setFormData((formData) => ({
			...formData,
			lastName: text,
			name: `${formData.firstName} ${text}`,
		}))
	// const updateEmail = text => setFormData(formData => ({ ...formData, email: text }))
	const updateTitle = (text) =>
		setFormData((formData) => ({ ...formData, title: text }))
	const updateBioTitle = (text) =>
		setFormData((formData) => ({
			...formData,
			introductionTitle: text,
		}))
	const updateSiteName = (text) =>
		setFormData((formData) => ({ ...formData, name: text }))

	const handleCheckFormLicenses = () => {
		formData.licenses.length === 0
			? setModalConfirmation({
					open: true,
					text: theme.modal.licensesText,
					leavePage: false,
					canBeSaved: false,
					isSave: true,
					event: null,
				})
			: saveForm()
	}

	const modalConfirm = async (modalConfirmation) => {
		modalConfirmation.leavePage
			? navigation.dispatch(modalConfirmation.event.data.action)
			: setModalConfirmation({
					open: false,
					text: '',
					leavePage: false,
				})
	}

	const openDeleteMeModal = () => {
		setModalDeleteMe((current) => ({ ...current, visible: true }))
	}

	const openModalEditMe = () => {
		setIsModalEditMeOpen(true)
	}

	const closeModalEditMe = () => {
		setIsModalEditMeOpen(false)
	}

	const changePasswordMutation = useMutation({
		mutationFn: ({ oldPassword, userPassword }) =>
			changePassword(oldPassword, userPassword),
	})

	const deleteUserMutation = useMutation({
		mutationFn: (data) => deleteMe(data),
	})

	const updateUserMutation = useMutation({
		mutationFn: (data) => updateMe(data),
	})

	const updateLoanOfficerSiteConfigurationMutation = useMutation({
		mutationFn: ({ data, loanOfficerId, siteConfigurationId }) =>
			updateLoanOfficerSiteConfiguration(
				data,
				loanOfficerId,
				siteConfigurationId
			),
	})

	const updatePartnerSiteConfigurationMutation = useMutation({
		mutationFn: ({ data, realtorId, siteConfigurationId }) =>
			updatePartnerSiteConfiguration(
				data,
				realtorId,
				siteConfigurationId
			),
	})

	const saveForm = async () => {
		try {
			const {
				id: formId,
				entityID,
				email,
				phone,
				firstName,
				lastName,
				title,
			} = formData
			if (formId) {
				await updateUserMutation.mutateAsync({
					id: entityID,
					email,
					phone: `+1${phone.replace(/\D/g, '')}`,
					firstName,
					lastName,
					title,
				})
				if (Roles.loanOfficer === user.role)
					await updateLoanOfficerSiteConfigurationMutation.mutateAsync(
						{
							data: formData,
							loanOfficerId: entityID,
							siteConfigurationId: formId,
						}
					)
				else if (Roles.realtor === user.role)
					await updatePartnerSiteConfigurationMutation.mutateAsync({
						data: formData,
						realtorId: entityID,
						siteConfigurationId: formId,
					})
				setInitialState(formData)
				alert('Your profile has been updated', {
					severity: 'success',
				})
			}
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleChangePassword = async () => {
		try {
			await changePasswordMutation.mutateAsync({
				oldPassword,
				userPassword,
			})
			setOldPassword('')
			setUserPassword('')
			alert('Account password successfully changed')
			mixpanel.trackEvent(eventTypes.USER_PASSWORD_UPDATED)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const onSubmit = async (feedback) => {
		try {
			await deleteUserMutation.mutateAsync(feedback)
			alert('Request for deletion was successfully sent', {
				severity: 'success',
			})
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const updateUserInfo = async (feedback) => {
		try {
			await updateUserMutation.mutateAsync(feedback)
			setInitialState({
				...formData,
				...feedback,
			})
			setIsModalEditMeOpen(false)
			alert('Your profile has been updated', {
				severity: 'success',
			})
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	return (
		<Page page="app-profile" title="My Profile" isFullWidth>
			<ModalYesNo
				modalConfirmation={modalConfirmation}
				modalConfirm={modalConfirm}
				setModalConfirmation={setModalConfirmation}
			/>
			<ModalUploadSizeLimit
				visible={modalSizeLimitVisible}
				closeModal={() => setModalSizeLimitVisible(false)}
			/>

			<ModalDeleteMe
				modal={modalDeleteMe}
				setModal={setModalDeleteMe}
				onSubmit={onSubmit}
			/>

			<ModalEditMe
				title="Edit Personal Information"
				open={isModalEditMeOpen}
				onClose={closeModalEditMe}
			>
				<UserProfileForm
					user={{
						...user,
						firstName: initialState.firstName,
						lastName: initialState.lastName,
						title: initialState.title,
					}}
					onSubmit={updateUserInfo}
				/>
			</ModalEditMe>

			<div className="pl-5 pr-5 pb-10 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">
					My Profile
				</p>
				<div className="flex flex-wrap justify-end my-5">
					<LoadingBtn
						id="AdminLoanOfficerEditSaveButton"
						disabled={!canBeSubmitted}
						loading={updateUserMutation.isPending}
						text="Update My Profile"
						onClick={handleCheckFormLicenses}
						fullWidth={false}
					/>
				</div>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={tab}
					onChange={handleTabChange}
					aria-label="Tabs"
				>
					<Tab label="Personal Information" {...a11yProps(0)} />
					<Tab label="Credentials" {...a11yProps(1)} />
					<Tab
						label={`${[Roles.loanOfficer, Roles.realtor].includes(user.role) ? 'Website ' : ''}Settings`}
						{...a11yProps(2)}
					/>
					{[Roles.loanOfficer, Roles.realtor].includes(user.role) && (
						<Tab label="Profile" {...a11yProps(3)} />
					)}
					{[Roles.loanOfficer, Roles.realtor].includes(user.role) && (
						<Tab label="Licensing" {...a11yProps(4)} />
					)}
				</Tabs>
				<SwipeableViews
					axis={muiTheme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={tab}
					onChangeIndex={handleChangeIndex}
				>
					<TabPanel value={tab} index={0} dir={muiTheme.direction}>
						<Card>
							<CardContent>
								{![Roles.loanOfficer, Roles.realtor].includes(
									user.role
								) ? (
									<div className="relative">
										<div className="absolute top-0 right-0">
											<Button
												type="button"
												onClick={openModalEditMe}
												text="Edit Info"
											/>
										</div>
										<div className="mb-2">
											<span className="font-bold">
												Email Address:
											</span>{' '}
											{user.email}
										</div>
										<div className="mb-2">
											<span className="font-bold">First Name: </span>
											{initialState.firstName}
										</div>
										<div className="mb-2">
											<span className="font-bold">Last Name:</span>{' '}
											{initialState.lastName}
										</div>
										<div className="mb-2">
											<span className="font-bold">Title:</span>{' '}
											{initialState.title}
										</div>
										<div>
											<span className="font-bold">
												Mobile Phone:&nbsp;
											</span>
											{user.phone && (
												<>
													{applyMask(
														user.phone.replace('+1'),
														'phone'
													)}
													{user.phoneVerified ? (
														<span className="text-green-500">
															{' '}
															(Verified)
														</span>
													) : (
														<span className="text-red-500">
															{' '}
															(Not Verified)
														</span>
													)}
												</>
											)}
										</div>
									</div>
								) : (
									<div className="w-1/3">
										<TextField
											id="AdminLoanOfficerProfileEmailInput"
											disabled
											value={formData?.email || ''}
											label="Email Address (can not be modified)"
											variant="standard"
											className="w-full sm:px-12"
											sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
										/>
										<TextField
											id="AdminLoanOfficerProfileFirstNameInput"
											placeholder="First Name (required)"
											value={formData?.firstName || ''}
											label="First Name *"
											onChange={(e) =>
												handleOnChangeText(
													e.target.value,
													'AdminLoanOfficerProfileFirstNameInput',
													null,
													updateFirstName
												)
											}
											error={handleInputValid(
												'AdminLoanOfficerProfileFirstNameInput'
											)}
											variant="standard"
											className="w-full sm:px-12"
											sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
										/>
										<TextField
											id="AdminLoanOfficerProfileLastNameInput"
											placeholder="Last Name (required)"
											value={formData?.lastName || ''}
											label="Last Name *"
											onChange={(e) =>
												handleOnChangeText(
													e.target.value,
													'AdminLoanOfficerProfileLastNameInput',
													null,
													updateLastName
												)
											}
											error={handleInputValid(
												'AdminLoanOfficerProfileLastNameInput'
											)}
											variant="standard"
											className="w-full sm:px-12"
											sx={{ mb: 2 }}
										/>
										<TextField
											id="AdminLoanOfficerProfileTitleInput"
											placeholder="Title"
											value={formData.title || ''}
											label="Title *"
											onChange={(e) =>
												handleOnChangeText(
													e.target.value,
													'AdminLoanOfficerProfileTitleInput',
													null,
													updateTitle
												)
											}
											error={handleInputValid(
												'AdminLoanOfficerProfileTitleInput'
											)}
											variant="standard"
											className="w-full sm:px-12"
											sx={{ mb: 2 }}
										/>
									</div>
								)}
								<div className="flex flex-wrap justify-start mt-5">
									<Button
										id="DeleteMyUser"
										text="Delete My User"
										color="error"
										onClick={openDeleteMeModal}
									/>
								</div>
							</CardContent>
						</Card>
					</TabPanel>
					<TabPanel value={tab} index={1} dir={muiTheme.direction}>
						<Card>
							<CardContent>
								<div className="flex flex-col items-center">
									<TextField
										onChange={(e) => setOldPassword(e.target.value)}
										value={oldPassword}
										label={oldPassword === '' ? null : 'Password'}
										placeholder="Old Password"
										id="ChangePasswordInput"
										variant="standard"
										type={showOldPassword ? 'text' : 'password'}
										className="w-64 mx-2.5 sm:w-96 sm:px-12"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() =>
															setShowOldPassword(!showOldPassword)
														}
														onMouseDown={() =>
															setShowOldPassword(!showOldPassword)
														}
													>
														{showOldPassword ? (
															<VisibilityIcon />
														) : (
															<VisibilityOffIcon />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</div>
								<ChangePassword
									id="ChangePassword"
									passwordLabel="New Password"
									confirmPasswordLabel="Confirm password"
									userPassword={userPassword}
									setUserPassword={setUserPassword}
									setConfirmButton={setEnabledButton}
									confirmButtonEnabled={enabledButton}
									passwordConfirmed={passwordConfirmed}
									setPasswordConfirmed={setPasswordConfirmed}
								/>
								<div className="flex flex-col items-center mt-5">
									<div className="w-64 mx-2.5 sm:w-96 sm:px-12">
										<LoadingBtn
											id="ChangePasswordButton"
											disabled={
												!enabledButton ||
												!oldPassword ||
												changePasswordMutation.isPending
											}
											loading={changePasswordMutation.isPending}
											onClick={handleChangePassword}
											fullWidth
											variant="contained"
											text="Change Password"
										/>
									</div>
								</div>
							</CardContent>
						</Card>
					</TabPanel>
					<TabPanel value={tab} index={2} dir={muiTheme.direction}>
						<UserProfileSettings />
					</TabPanel>
					<TabPanel value={tab} index={3} dir={muiTheme.direction}>
						<Card>
							<CardContent>
								<div className="flex">
									<div className="w-full md:ml-5 md:w-1/2 md:mx-2 md:mb-0 md:max-w-screen-sm">
										<TextField
											id="AdminLoanOfficerProfileSiteNameInput"
											placeholder="Site Name (required)"
											label="Site Name *"
											value={formData.name || ''}
											onChange={(e) =>
												handleOnChangeText(
													e.target.value,
													'AdminLoanOfficerProfileSiteNameInput',
													null,
													updateSiteName
												)
											}
											error={handleInputValid(
												'AdminLoanOfficerProfileSiteNameInput'
											)}
											variant="standard"
											className="w-full sm:px-12"
											sx={{ mb: 2 }}
										/>
										<ImagePicker
											title="Headshot (300 x 300)"
											value={formData?.profilePhotoUrl}
											handleImagePicker={handleImagePicker}
											field="profilePhotoUrl"
											id="WebsiteSettingsUpdateProfilePhotoImageButton"
										/>
										{[Roles.loanOfficer, Roles.realtor].includes(
											user.role
										) && (
											<>
												<div className="flex flex-row w-full items-end">
													<FormControl
														key="WebsiteSettingsLandingPageTypePicker"
														variant="standard"
														className="w-full sm:px-12"
														sx={{ mb: 2, mr: 2 }}
													>
														<InputLabel>Landing Page Type</InputLabel>
														<Select
															value={formData?.landingPageType || 0}
															onChange={(e) =>
																updatePOSSiteConfiguration(
																	'landingPageType',
																	e.target.value
																)
															}
															label="Landing Page Type"
														>
															<MenuItem value={0}>
																Default Landing Page
															</MenuItem>
															<MenuItem value={1}>
																Condensed Landing Page
															</MenuItem>
														</Select>
													</FormControl>
												</div>
												<div
													className={`${width >= 468 ? 'flex-row' : 'flex-col'} flex w-full items-end`}
												>
													<TextField
														id="AdminAccountWebsiteSettingsFacebookUrl"
														placeholder="Enter Facebook URL"
														value={formData?.facebookUrl}
														label="Facebook"
														onChange={(e) =>
															updatePOSSiteConfiguration(
																'facebookUrl',
																e.target.value
															)
														}
														variant="standard"
														className="w-full sm:px-12"
														sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
													/>
													<TextField
														id="AdminAccountWebsiteSettingsTwitterUrl"
														placeholder="Enter Twitter URL"
														value={formData?.twitterUrl}
														label="Twitter"
														onChange={(e) =>
															updatePOSSiteConfiguration(
																'twitterUrl',
																e.target.value
															)
														}
														variant="standard"
														className="w-full sm:px-12"
														sx={{ mb: 2 }}
													/>
												</div>
												<div
													className={`${width >= 468 ? 'flex-row' : 'flex-col'} flex w-full items-end`}
												>
													<TextField
														id="AdminAccountWebsiteSettingsInstagramUrl"
														placeholder="Enter Instagram URL"
														value={formData?.instagramUrl}
														label="Instagram"
														onChange={(e) =>
															updatePOSSiteConfiguration(
																'instagramUrl',
																e.target.value
															)
														}
														variant="standard"
														className="w-full sm:px-12"
														sx={{ mb: 2, mr: width > 468 ? 2 : 0 }}
													/>
													<TextField
														id="AdminAccountWebsiteSettingsLinkedInUrl"
														placeholder="Enter LinkedIn URL"
														value={formData?.linkedInUrl}
														label="LinkedIn"
														onChange={(e) =>
															updatePOSSiteConfiguration(
																'linkedInUrl',
																e.target.value
															)
														}
														variant="standard"
														className="w-full sm:px-12"
														sx={{ mb: 2 }}
													/>
												</div>
											</>
										)}
									</div>
									{[Roles.loanOfficer, Roles.realtor].includes(
										user.role
									) && (
										<div className="w-full md:ml-5 md:w-1/2 md:mx-2 md:mb-0 md:max-w-screen-sm">
											<TextField
												id="AdminLoanOfficerBioTitleInput"
												placeholder="My Bio Title"
												value={formData.introductionTitle || ''}
												label="Bio Title"
												onChange={(e) =>
													handleOnChangeText(
														e.target.value,
														'AdminLoanOfficerBioTitleInput',
														null,
														updateBioTitle
													)
												}
												variant="standard"
												className="w-full sm:px-12"
												sx={{ mb: 2 }}
											/>

											<p className="mt-5 md:mt-0 mb-2.5 font-rubik text-sm">
												Bio
											</p>
											<TextEditor
												height="600px"
												placeholder="Type something..."
												initialValue={formData.introduction}
												onChange={handleIntroductionChange}
											/>
										</div>
									)}
								</div>
							</CardContent>
						</Card>
					</TabPanel>
					{[Roles.loanOfficer, Roles.realtor].includes(user.role) && (
						<TabPanel value={tab} index={4} dir={muiTheme.direction}>
							<Card>
								<CardContent>
									<StateLicensesList licenses={formData.licenses} />
								</CardContent>
							</Card>
						</TabPanel>
					)}
				</SwipeableViews>
			</div>
		</Page>
	)
}
