// NPM packages
import React from 'react'
import * as yup from 'yup'
// Components
import { Form, TextField } from '../components/formControls'
import { Button } from '../components/Button'
// Services
import useForm from '../hooks/useForm'

const schema = yup
	.object()
	.shape({
		firstName: yup.string().required(),
		lastName: yup.string().required(),
		title: yup.string(),
	})
	.required()

const UserProfileForm = ({ user, onSubmit }) => {
	const {
		control,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		schema,
		defaultValues: {
			firstName: user.firstName ?? '',
			lastName: user.lastName ?? '',
			title: user.title ?? '',
		},
	})

	const handleFormSubmit = (data) => {
		if (data && user) {
			const feedback = {
				firstName: data.firstName,
				lastName: data.lastName,
				title: data.title,
				phone: user.phone,
				forcedPasswordReset: user.forcedPasswordReset,
				mfaEnabled: user.mfaEnabled,
			}

			onSubmit(feedback)
		}
	}
	return (
		<Form onSubmit={handleSubmit(handleFormSubmit)}>
			<div className="flex flex-col gap-4">
				<div>
					<TextField
						name="firstName"
						label="First Name"
						fullWidth
						control={control}
						errors={errors}
						required
					/>
				</div>
				<div>
					<TextField
						name="lastName"
						label="Last Name"
						fullWidth
						control={control}
						errors={errors}
						required
					/>
				</div>
				<div>
					<TextField
						name="title"
						label="Title"
						fullWidth
						control={control}
						errors={errors}
					/>
				</div>
				<div className="mt-6">
					<Button type="submit" text="Submit" />
				</div>
			</div>
		</Form>
	)
}

export default UserProfileForm
