import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import { useTheme } from '@mui/material/styles'
import { isEqual } from 'lodash'
import { useVenti } from 'venti'
import {
	Tabs,
	Tab,
	Card,
	CardContent,
	FormControlLabel,
	Checkbox,
	Typography,
} from '@mui/material'

import Page from '../../../components/Page'
import { LoadingBtn } from '../../../components/Button'
import { ModalYesNo } from '../../../components/modals/ModalYesNo'
import { ModalError } from '../../../components/modals/ModalError'
import { useAppContext } from '../../../components/AppContext'
import { Loading } from '../../../components/Loading'
import { Features } from '../../../components/admin/Features'
import { WebsiteSettings } from '../../../components/admin/WebsiteSettings'
import { Integrations } from '../../../components/admin/Integrations'
import TabPanel from '../../../components/TabPanel'
import ProfileView from './Profile'

import { navigationLinking } from '../../../services/navigation'
import { siteConfigurationModel } from '../../../services/utils'
import {
	createBranch,
	createBranchSiteConfiguration,
	getBranchSiteConfiguration,
	updateBranchSiteConfiguration,
	getBranch,
	updateBranch,
} from '../../../services/client'
import {
	a11yProps,
	getErrorMessage,
	validateSiteConfigurationData,
} from '../../../services/helper'
import { eventTypes } from '../../../services/constants'
import queryKeys from '../../../services/queryKeys'

import { useAlert, useWindowSize } from '../../../hooks'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { getTheme } from '../../../config'

const theme = getTheme()

export default function AdminBranchEdit({ navigation }) {
	const { alert } = useAlert()
	const { id } = useParams()
	const { state } = useAppContext()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const themeMUI = useTheme()
	const { siteConfig } = state
	const ventiState = useVenti()
	const [width] = useWindowSize()
	const mixpanel = useMixpanel()
	const isCreateNewBranch = id === 'new'
	const siteConfigurationId = ventiState.get(
		theme.storageKeys.editingBranchSiteConfigurationId
	)
	const [tab, setTab] = useState(0)
	const [modalConfirmation, setModalConfirmation] = useState({
		open: false,
		text: '',
		leavePage: false,
		canBeSaved: false,
		isSave: false,
		event: null,
	})
	const [initialState, setInitialState] = useState({
		...siteConfigurationModel,
		updateDownstream: false,
		domain: '',
		entityType: 2,
	})
	const [formData, setFormData] = useState({
		...siteConfigurationModel,
		updateDownstream: false,
		domain: '',
		entityType: 2,
	})
	const [inheritedSiteConfig, setInheritedSiteConfig] = useState({})
	const [canBeSubmitted, setCanBeSubmitted] = useState(true)
	const [errorModalVisible, setErrorModalVisible] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')
	const initialRender = useRef(true)

	const {
		error: errorFetchingBranch,
		data: branchResult,
		refetch,
	} = useQuery({
		queryKey: [queryKeys.branch, id],
		queryFn: () => getBranch(id),
		enabled: !!id && !isCreateNewBranch,
		retry: 2,
	})

	const {
		error: errorFetchingBranchSiteConfiguration,
		data: branchSiteConfigurationResult,
		refetch: refetchBranchSiteConfiguration,
	} = useQuery({
		queryKey: [
			queryKeys.branchSiteConfiguration,
			siteConfigurationId,
		],
		queryFn: () =>
			getBranchSiteConfiguration(id, siteConfigurationId),
		enabled: !!id && !isCreateNewBranch && !!siteConfigurationId,
		retry: 2,
	})

	useEffect(() => {
		if (!id) navigate(`/${navigationLinking.AdminBranch}`)
	}, [id])

	useEffect(() => {
		if (errorFetchingBranch) {
			alert('There was a problem loading the branch', {
				severity: 'error',
			})
		}
	}, [errorFetchingBranch])

	useEffect(() => {
		if (errorFetchingBranchSiteConfiguration) {
			alert(
				'There was a problem loading the branch site configuration',
				{
					severity: 'error',
				}
			)
		}
	}, [errorFetchingBranchSiteConfiguration])

	useEffect(() => {
		const formCopy = { ...formData }
		delete formCopy.domain

		const canBeSubmitted =
			formData?.url?.trim() !== '' &&
			formData?.licenses?.length > 0 &&
			formData?.name?.length > 0 &&
			!isEqual(initialState, formCopy)
		setCanBeSubmitted(canBeSubmitted)

		if (initialRender.current) {
			initialRender.current = false
		}
	}, [formData, initialState])

	useEffect(() => {
		if (branchResult && branchSiteConfigurationResult) {
			setFormData({
				...branchSiteConfigurationResult.siteConfiguration,
				corporateId: branchResult.corporateID,
				type: branchResult.type,
			})
			setInheritedSiteConfig(
				branchSiteConfigurationResult.inheritedSiteConfiguration
			)
			setInitialState(branchSiteConfigurationResult.siteConfiguration)
		}
	}, [branchResult, branchSiteConfigurationResult])

	const invalidateQueries = async () => {
		await queryClient.invalidateQueries({
			queryKey: [queryKeys.branches, queryKeys.branch],
		})
		await refetch()
	}

	const invalidateSiteConfigurationQueries = async () => {
		await queryClient.invalidateQueries({
			queryKey: [
				queryKeys.branchSiteConfiguration,
				siteConfigurationId,
			],
		})
		await refetchBranchSiteConfiguration()
	}

	const createBranchMutation = useMutation({
		mutationFn: (data) => createBranch(data),
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: [queryKeys.branches],
			})
		},
	})

	const createBranchSiteConfigurationMutation = useMutation({
		mutationFn: ({ formData, branchId }) =>
			createBranchSiteConfiguration(formData, branchId),
	})

	const updateBranchSiteConfigurationMutation = useMutation({
		mutationFn: ({ formData, branchId, siteConfigurationId }) =>
			updateBranchSiteConfiguration(
				formData,
				branchId,
				siteConfigurationId
			),
		onSuccess: invalidateSiteConfigurationQueries,
	})

	const updateBranchMutation = useMutation({
		mutationFn: (data) => updateBranch(data),
		onSuccess: invalidateQueries,
	})

	const saveForm = async () => {
		try {
			const {
				id: formId,
				name,
				corporateId,
				type,
				url,
				domain,
				branchId,
			} = formData
			// Check the URL is the correct format and matches the domain
			const parts = url.split('.')
			if (parts.length !== 3) {
				setErrorMsg(
					`The format of the URL you've entered (${url}) is not valid. 
				It should appear like branch.${domain}`
				)
				setErrorModalVisible(true)
				return
			}

			const subdomain = parts.shift()
			if (parts.join('.') !== domain) {
				setErrorMsg(
					`The URL you've entered (${url}) does not match the domain of the brand. 
				It should appear like ${subdomain}.${domain}`
				)
				setErrorModalVisible(true)
				return
			}

			if (formId) {
				await updateBranchMutation.mutateAsync({
					id,
					name: name,
					corporateID: corporateId,
					type,
				})
				await updateBranchSiteConfigurationMutation.mutateAsync({
					formData,
					branchId: id,
					siteConfigurationId: formId,
				})

				mixpanel.trackEvent(eventTypes.BRANCH_UPDATED, {
					name,
					id: formId,
				})
				alert(`Branch "${name}" successfully updated`)
				navigate(`/${navigationLinking.AdminBranch}`)
			} else {
				const branch = await createBranchMutation.mutateAsync({
					name,
					corporateID: branchId,
					type,
				})

				validateSiteConfigurationData(formData)
				await createBranchSiteConfigurationMutation.mutateAsync({
					formData,
					branchId: branch.id,
				})

				mixpanel.trackEvent(eventTypes.BRANCH_CREATED, {
					name,
					id: branch.id,
				})
				alert(`Branch "${name}" successfully created`)
				navigate(`/${navigationLinking.AdminBranch}`)
			}
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleCheckFormLicenses = () => {
		formData.licenses.length === 0
			? setModalConfirmation({
					open: true,
					text: theme.modal.licensesText,
					leavePage: false,
					canBeSaved: false,
					isSave: true,
					event: null,
				})
			: saveForm()
	}

	const modalConfirm = async (modalConfirmation) => {
		modalConfirmation.leavePage
			? navigation.dispatch(modalConfirmation.event.data.action)
			: setModalConfirmation({
					open: false,
					text: '',
					leavePage: false,
				})
	}

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	return (
		<Page
			isFullWidth={true}
			title={isCreateNewBranch ? `New Branch` : 'Edit Branch'}
		>
			<ModalYesNo
				modalConfirmation={modalConfirmation}
				modalConfirm={modalConfirm}
				setModalConfirmation={setModalConfirmation}
			/>
			<ModalError
				visible={errorModalVisible}
				setVisible={setErrorModalVisible}
				message={errorMsg}
			/>
			<div className="pl-5 pr-5 pb-20 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">
					{isCreateNewBranch ? `New Branch` : 'Edit Branch'}
				</p>
				{!isCreateNewBranch && !initialState?.id ? (
					<div
						className="flex justify-center dark:bg-[#121212]"
						style={{ height: `calc(100vh - 216px)` }}
					>
						<Loading size="small" />
					</div>
				) : (
					<>
						<div className="flex flex-wrap justify-end my-5">
							{/*{formData?.id && (*/}
							{/*	<FormControlLabel*/}
							{/*		label={*/}
							{/*			<Typography className="dark:text-white">*/}
							{/*				Apply to All Child Sites*/}
							{/*			</Typography>*/}
							{/*		}*/}
							{/*		key="AdminBranchEditApplyToChildrenButton"*/}
							{/*		control={*/}
							{/*			<Checkbox*/}
							{/*				id="AdminBranchEditApplyToChildrenButton"*/}
							{/*				checked={formData?.updateDownstream || false}*/}
							{/*				onChange={() =>*/}
							{/*					setFormData((current) => ({*/}
							{/*						...current,*/}
							{/*						updateDownstream: !current.updateDownstream,*/}
							{/*					}))*/}
							{/*				}*/}
							{/*				inputProps={{ 'aria-label': 'controlled' }}*/}
							{/*			/>*/}
							{/*		}*/}
							{/*	/>*/}
							{/*)}*/}
							<LoadingBtn
								id={`AdminBranchEditSaveButton`}
								disabled={!canBeSubmitted}
								loading={
									updateBranchMutation.isPending ||
									createBranchMutation.isPending
								}
								text={`${formData?.id ? 'Update Branch' : 'Create Branch'}`}
								onClick={handleCheckFormLicenses}
								fullWidth={false}
							/>
						</div>
						<Tabs
							variant="scrollable"
							scrollButtons="auto"
							value={tab}
							onChange={handleTabChange}
							aria-label="Tabs"
						>
							<Tab label="Branch Profile" {...a11yProps(0)} />
							<Tab label="Branding & Images" {...a11yProps(1)} />
							<Tab label="Features" {...a11yProps(2)} />
							<Tab label="Integrations" {...a11yProps(3)} />
						</Tabs>
						<SwipeableViews
							axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
							index={tab}
							onChangeIndex={handleChangeIndex}
						>
							<TabPanel
								value={tab}
								index={0}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<ProfileView
											siteConfig={siteConfig}
											formData={formData}
											inheritedSiteConfiguration={inheritedSiteConfig}
											setFormData={setFormData}
											width={width}
										/>
									</CardContent>
								</Card>
							</TabPanel>
							<TabPanel
								value={tab}
								index={1}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<WebsiteSettings
											formData={formData}
											setFormData={setFormData}
											inheritedSiteConfiguration={inheritedSiteConfig}
											entityType={2}
											width={width}
										/>
									</CardContent>
								</Card>
							</TabPanel>
							<TabPanel
								value={tab}
								index={2}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<Features
											formData={formData}
											setFormData={setFormData}
											inheritedSiteConfiguration={inheritedSiteConfig}
										/>
									</CardContent>
								</Card>
							</TabPanel>
							<TabPanel
								value={tab}
								index={3}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<Integrations
											formData={formData}
											setFormData={setFormData}
											inheritedSiteConfiguration={inheritedSiteConfig}
											entityType={2}
											width={width}
										/>
									</CardContent>
								</Card>
							</TabPanel>
						</SwipeableViews>
					</>
				)}
			</div>
		</Page>
	)
}
