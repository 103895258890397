import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useVenti } from 'venti'
import { map } from 'lodash'
import { ModalRemoveRestoreRecordConfirm } from '../../../components/modals/ModalRemoveRestoreRecordConfirm'
import Page from '../../../components/Page'
import DataTable from '../../../components/DataTable'
import {
	updateBusinessRule,
	getBusinessRule,
} from '../../../services/client'
import { getErrorMessage } from '../../../services/helper'
import { getTheme } from '../../../config'
import { useAlert } from '../../../hooks'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { eventTypes } from '../../../services/constants'
import withAuth from '../../../components/withAuth'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import queryKeys from '../../../services/queryKeys'
import { cloneDeep } from 'lodash'
import ModalTaskForm from '../../../components/modals/ModalTaskForm'
import AddRuleTaskButtonGroup from './components/AddRuleTaskButtonGroup'
import ModalExistingTaskForm from './components/ModalExistingTaskForm'
import TaskRowExpanded from './components/TaskRowExpanded'

const theme = getTheme()

function AdminRuleTasks() {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const mixpanel = useMixpanel()
	const { id: ruleId } = useParams()
	const queryClient = useQueryClient()

	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [filteredTasks, setFilteredTasks] = useState([])
	const [searchText, setSearchText] = useState('')
	const [taskModalOpen, setTaskModalOpen] = useState(false)
	const [existingTaskModalOpen, setExistingTaskModalOpen] =
		useState(false)
	const [selectedTask, setSelectedTask] = useState(null)

	const {
		isFetching,
		isRefetching,
		isError,
		data: businessRule,
		refetch,
	} = useQuery({
		queryKey: [queryKeys.businessRule, [ruleId]],
		queryFn: () => getBusinessRule(ruleId),
	})

	useEffect(() => {
		if (isError) {
			alert('There was a problem loading the business rule', {
				severity: 'error',
			})
		}
	}, [isError, alert])

	const updateRuleMutation = useMutation({
		mutationFn: async (data) => updateBusinessRule(ruleId, data),
	})

	const columns = [
		{
			name: 'Name',
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: 'Type',
			selector: (row) => row.type,
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'LOS Field ID or Bucket Name',
			selector: (row) => row.losTarget,
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Days Due from App',
			selector: (row) => row.daysDueFromApplication,
			hide: theme.breakpoints.dataTable,
		},
	]

	useEffect(() => {
		if (businessRule) {
			if (searchText) {
				const filteredData = businessRule.tasks.filter((r) =>
					r.name.toLowerCase().includes(searchText.toLowerCase())
				)
				setFilteredTasks(filteredData)
			} else {
				setFilteredTasks(businessRule.tasks)
			}
		}
	}, [businessRule, searchText])

	const refetchAndInvalidate = async () => {
		await refetch()
		await queryClient.invalidateQueries({
			queryKey: [queryKeys.businessRules, queryKeys.businessRule],
		})
	}

	const handleTaskSubmit = async (data) => {
		try {
			const { id, name } = data
			const request = cloneDeep(businessRule)
			if (id) {
				const taskIndex = request.tasks.findIndex(
					(task) => task.id === data.id
				)
				if (taskIndex === -1) {
					request.tasks.push(data)
				} else {
					request.tasks[taskIndex] = data
				}
			} else {
				request.tasks.push(data)
			}

			await updateRuleMutation.mutateAsync(request)
			await refetchAndInvalidate()
			const eventType = id
				? eventTypes.BUSINESS_RULE_TASK_UPDATED
				: eventTypes.BUSINESS_RULE_TASK_CREATED

			mixpanel.trackEvent(eventType, {
				name,
				...(id && { id }),
			})

			alert(
				`Task "${name}" successfully ${id ? 'updated' : 'created'}`
			)

			setTaskModalOpen(false)
			setExistingTaskModalOpen(false)
			setSelectedTask(null)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	const showRemoveModal = (row) => {
		setRemoveModalVisible(true)
		setSelectedTask(row)
	}

	const removeTask = async () => {
		setRemoveModalVisible(false)

		try {
			const { id, name } = selectedTask
			const request = cloneDeep(businessRule)
			const taskIndex = request.tasks.findIndex((t) => t.id === id)
			request.tasks.splice(taskIndex, 1)

			await updateRuleMutation.mutateAsync(request)
			await refetchAndInvalidate()

			mixpanel.trackEvent(eventTypes.BUSINESS_RULE_TASK_REMOVED, {
				name,
				id,
			})
			alert(`Task "${name}" successfully removed`)
			setSelectedTask(null)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	const handleSearchChange = (e) => {
		setSearchText(e.target.value)
	}
	const handleSearchClear = () => {
		setSearchText('')
	}

	const handleAddClick = () => {
		setTaskModalOpen(true)
	}

	const handleEditClick = (row) => {
		setSelectedTask(row)
		setTaskModalOpen(true)
	}

	const handleTaskModalClose = () => {
		setTaskModalOpen(false)
		setSelectedTask(null)
	}

	const handleExistingTaskClick = () => {
		setExistingTaskModalOpen(true)
	}

	const handleExistingTaskModalClose = () => {
		setExistingTaskModalOpen(false)
	}

	const excludedBusinessRuleTasks = useMemo(() => {
		return businessRule ? map(businessRule.tasks, 'id') : []
	}, [businessRule])

	return (
		<Page title="Tasks" isFullWidth={true}>
			<ModalTaskForm
				open={taskModalOpen}
				task={selectedTask}
				onSubmit={handleTaskSubmit}
				onClose={handleTaskModalClose}
			/>

			<ModalExistingTaskForm
				open={existingTaskModalOpen}
				onSubmit={handleTaskSubmit}
				onClose={handleExistingTaskModalClose}
				excludedIds={excludedBusinessRuleTasks}
			/>

			<div className="pl-5 pr-5 pb-10 h-screen overflow-auto">
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={removeTask}
					row={selectedTask}
				/>

				<DataTable
					data={filteredTasks}
					columns={columns}
					defaultSortAsc={false}
					defaultSortFieldId="createdAt"
					pagination={true}
					progressPending={isFetching}
					refreshing={isRefetching}
					title="Tasks"
					onRefreshClick={refetch}
					keyField="id"
					searchText={searchText}
					onSearchChange={handleSearchChange}
					onClearSearchClick={handleSearchClear}
					expandableRowsComponent={TaskRowExpanded}
					actionItems={[
						{
							name: 'Edit',
							onClick: (e, row) => handleEditClick(row),
						},
						{
							name: 'Delete',
							onClick: (e, row) => showRemoveModal(row),
						},
					]}
					addNewBtn={
						<AddRuleTaskButtonGroup
							onNewClick={handleAddClick}
							onExistingClick={handleExistingTaskClick}
						/>
					}
				/>
			</div>
		</Page>
	)
}

export default withAuth(AdminRuleTasks)
