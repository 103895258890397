import React from 'react'
import clsx from 'clsx'
import WarningIcon from '@mui/icons-material/Warning'

const ErrorList = ({ title, errors, className = undefined }) => {
	if (!errors || errors.length === 0) return null
	return (
		<div className={clsx('bg-red-100 p-3 rounded', className)}>
			{title && <h2 className="text-red-500 text-lg">{title}</h2>}
			<div className="flex items-center">
				<WarningIcon className="text-red-700 mr-2" />
				<ul>
					{errors.map((error) => (
						<li
							key={error}
							className="text-red-700 text-sm font-bold"
						>
							{error.property ||
								(error.propertyName && (
									<strong>
										{error.property || error.propertyName}
									</strong>
								))}{' '}
							{error.message || error.error || error.errorMessage}
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}

export default ErrorList
